import {
  PUBLIC_BASE_URL,
  PUBLIC_EVENT_TRACKER_RECONNECT_INTERVAL,
  PUBLIC_EVENT_TRACKER_URL,
  PUBLIC_FILES_SIZE_LIMIT,
  PUBLIC_LOGIN_REDIRECT_URL,
  PUBLIC_ML_API_URL,
  PUBLIC_SENTRY_DSN,
  PUBLIC_SENTRY_ORG,
  PUBLIC_SENTRY_PROJECT,
  PUBLIC_SENTRY_TRACE_RATE,
  PUBLIC_SYM_CHAT_WS_URL,
  PUBLIC_TAIGA_API_URL,
  PUBLIC_TAIGA_FRONT_URL,
  PUBLIC_TAIGA_WS_URL,
} from '$env/static/public';

export const env = {
  baseUrl: PUBLIC_BASE_URL,
  eventTrackerUrl: PUBLIC_EVENT_TRACKER_URL,
  eventTrackerReconnectInterval: PUBLIC_EVENT_TRACKER_RECONNECT_INTERVAL,
  loginRedirectUrl: PUBLIC_LOGIN_REDIRECT_URL,
  symChatWsUrl: PUBLIC_SYM_CHAT_WS_URL,
  sentryOrg: PUBLIC_SENTRY_ORG,
  sentryProject: PUBLIC_SENTRY_PROJECT,
  sentryDSN: PUBLIC_SENTRY_DSN,
  sentryTraceRate: Number(PUBLIC_SENTRY_TRACE_RATE),
  fileSizeLimit: Number(PUBLIC_FILES_SIZE_LIMIT),
  taigaAPI: PUBLIC_TAIGA_API_URL,
  taigaFront: PUBLIC_TAIGA_FRONT_URL,
  mlAPI: PUBLIC_ML_API_URL,
  taigaWS: PUBLIC_TAIGA_WS_URL,
};
